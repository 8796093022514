<!--
 * @Description: 商品详情页面组件
 * @Author: hai-27
 * @Date: 2020-02-16 20:20:26
 * @LastEditors: hai-27
 * @LastEditTime: 2020-03-07 21:59:26
 -->
<template>
	<div id="details">


		<!-- 主要内容 -->
		<div class="goods width1225">
			<!-- 面包屑 -->
			<div class="breadcrumb">
				<el-breadcrumb separator-class="el-icon-arrow-right">
					<el-breadcrumb-item>
						<router-link to="/">首页</router-link>
					</el-breadcrumb-item>
					<el-breadcrumb-item>
						<router-link to="/goods">全部商品</router-link>
					</el-breadcrumb-item>
					<el-breadcrumb-item>{{ productDetails.Name }}</el-breadcrumb-item>
					<!-- <el-breadcrumb-item v-else>分类</el-breadcrumb-item> -->
					<!-- <el-breadcrumb-item v-if="search">{{ search }}</el-breadcrumb-item> -->
				</el-breadcrumb>
			</div>
		</div>
		<div class="main">
			<div class="width1225">
				<div class="product-detail-top">
					<div class="msg clear" data-alert="0">
						<div class="img-div fl">
							<img :src="getUrl(productDetails.ImgUrl)">

						</div>
						<div class="parameter-div fl" v-if="productDetails">
							<h1 class="title bold">{{ productDetails.Name }}</h1>
							<div class="parameter-items clear">
								<div class="parameter-item lb-box fl parameter-item-l">
									<strong>
										<span class="line-block item-span va-m">型&nbsp;&nbsp;&nbsp;&nbsp;号：</span>
										<div class="line-block zzs-p va-m"><a
												title="FI-XC3A-1-15000现货采购价格_元器件采购商城">{{ productDetails.SpuInfo.Name }}</a>
										</div>
									</strong>
								</div>
								<div class="parameter-item lb-box fl parameter-item-r">
									<span class="line-block  item-span va-m">起订量：</span>
									<p class="line-block qdl-p va-m">{{ productDetails.qidingshu}}</p>
								</div>
							</div>
							<div class="parameter-items clear">
								<div class="parameter-item lb-box fl parameter-item-l">
									<span class="line-block  item-span va-m">品&nbsp;&nbsp;&nbsp;&nbsp;牌：</span>
									<p class="line-block zzs-p ellipsis va-m">
										<router-link
											:to="{ path: '/MerchantShop' , query: { BrandId:productDetails.BrandInfo.Id } }">
											{{ productDetails.BrandInfo.Name }}
										</router-link>
									</p>
								</div>
								<div class="parameter-item  lb-box  fl  parameter-item-r">
									<span class="line-block item-span va-m">倍&nbsp;&nbsp;&nbsp;&nbsp;数：</span>
									<p class="line-block bs-p va-m">{{ productDetails.beishu}}</p>
								</div>
							</div>
							<div class="parameter-items clear ">
								<!-- <div class="parameter-item lb-box fl parameter-item-l">
									<span class="line-block item-span va-m">封&nbsp;&nbsp;&nbsp;&nbsp;装：</span>
									<p class="line-block va-m">--</p>
								</div> -->
								<div class="parameter-item lb-box fl  parameter-item-l">
									<span class="line-block item-span va-m">库&nbsp;&nbsp;&nbsp;&nbsp;存：</span>
									<p class="line-block kc-p va-m">{{ productDetails.SkuViewList[0].Stock }}</p>

								</div>
								<div class="parameter-item lb-box fl  parameter-item-r">
									<span class="line-block item-span va-m">批&nbsp;&nbsp;&nbsp;&nbsp;次：</span>
									<p class="line-block kc-p va-m">{{ productDetails.pici}}</p>

								</div>
							</div>
							<div class="parameter-items clear">
								<div class="parameter-item lb-box fl parameter-item-l">
									<span class="line-block item-span va-m">供应商：</span>
									<p class="line-block zzs-p va-m"><router-link
											:to="{ path: '/MerchantShop' , query: { SupplierId:productDetails.SupplierInfo.Id } }">{{ productDetails.SupplierInfo.Name }}</router-link>
									</p>

								</div>
								<!-- <div class="parameter-item lb-box fl  parameter-item-r">
									<span class="line-block item-span va-m">批&nbsp;&nbsp;&nbsp;&nbsp;次：</span>
									<p class="line-block va-m">22+</p>
								</div> -->
							</div>
							<div class="lb-box clf">
								<span class="line-block va-m">类&nbsp;&nbsp;&nbsp;&nbsp;目：</span>
								<p class="line-block va-m">{{productDetails.ClassName}}</p>
							</div>

							<div class="desc clear">
								<span class="desc-title fl">描&nbsp;&nbsp;&nbsp;&nbsp;述：</span>
								<p class="desc-text fl">{{productDetails.Intro?productDetails.Intro:'--'}}</p>
							</div>
							<div class="addr-pric">
								<div class="address-choose lb-box">
									<div class="text line-block item-span va-m">货&nbsp;&nbsp;&nbsp;&nbsp;期：</div>
									<div class="lb-box line-block dl-choose item-choose active va-m">
										<span class="line-block va-m">3-5个工作日</span>
										<!-- <span class="line-block va-m">{{productDetails.SkuViewList[0].Name}}</span> -->
									</div>
								</div>
								<div class="price-operate lb-box" data-model="FI-XC3A-1-15000" data-supplier="13"
									data-price="0.0441" data-gyscode="G11308">
									<div class="text line-block item-span va-m">数&nbsp;&nbsp;&nbsp;&nbsp;量：</div>
									<div class="lb-box line-block account-num va-m">
										<el-input-number v-model="productNum"
											:min="productDetails.qidingshu" :step="productDetails.beishu"
											:step-strictly="true" :max="productDetails.SkuViewList[0].Stock"
											size="mini">
										</el-input-number>
									</div>
									<div class="lb-box line-block one-price">
										<span class="va-m">单价：</span>
										<span class="va-m price-text"
											data-price="0.0441">￥{{ productDetails.SkuViewList[0].Price }}</span>
									</div>
									<div class="lb-box line-block total-price">
										<span class="va-m">总价：</span>
										<span
											class="va-m price-text">￥{{ (productDetails.SkuViewList[0].Price * productDetails.SkuViewList[0].Num).toFixed(2) }}</span>
									</div>
								</div>
							</div>
							<div class="btns">
								<div class="yh-btns clear">
									<a href="javascript:void(0)" data-id="116464406537333"
										class="btn fl  common-btn  common-btn-red-border common-btn-radius now-buy-btn"
										rel="nofollow" @click="payImmediately">立即购买</a>
									<a href="javascript:void(0)" data-id="116464406537333"
										class="btn fl  btn-r-b common-btn  common-btn-red-white-border common-btn-radius now-cart-btn"
										rel="nofollow" @click="addShoppingCart">加入购物车</a>
								</div>

							</div>
						</div>
					</div>
				</div>
				<div class="product-detail-bottom clear">
					<div class="left-section fl boxsiz">
						<ul class="left-nav-ul clear">
							<li class="left-nav-li fl boxsiz" :class="detailIndex==0?'active':''" data-type="1"
								@click="currentClick(0)">商品详情</li>

							<!-- <li class="left-nav-li fl boxsiz  last-li" :class="detailIndex==1?'active':''" data-type="2" @click="currentClick(1)">常见问题</li> -->
						</ul>
						<div style="margin-top: 24px;">
							<p v-html="productDetails.Content"></p>
						</div>
					</div>

				</div>
			</div>


			<!-- <div class="Bottomdetail">
				 
			</div> -->
		</div>
		<!-- 主要内容END -->
	</div>
</template>
<script>
	import {
		mapActions
	} from "vuex";
	export default {
		data() {
			return {
				productNum: 1,
				dis: false, // 控制“加入购物车按钮是否可用”
				productID: "", // 商品id
				productDetails: "", // 商品详细信息
				productPicture: "", // 商品图片
				productContent: '',
				TopGoods: [],
				search: '',
				detailIndex: 0,
			};
		},
		// 通过路由获取商品id
		activated() {
			if (this.$route.query.id != undefined) {
				this.productID = this.$route.query.id;
				this.getDetails(this.productID);
			}
		},
		watch: {
			// 监听商品id的变化，请求后端获取商品数据
			productID: function(val) {
				this.getDetails(val);
			}
		},
		created() {
			// 获取分类列表
			// this.getCategory();
			// this.getDetails(this.productID);
		},
		methods: {
			...mapActions(["unshiftShoppingCart", "addShoppingCartNum"]),
			// 获取商品详细信息
			async getData() {
				// 如果分类列表为空则请求全部商品数据，否则请求分类商品数据
				let res2 = await this.postdata("/api/Goods/GoodsList", {
					page: 1,
					limit: 4,
					"IsTop": 1, //推荐 1-是
				})
				if (res2.code == 200) {
					this.TopGoods = res2.data.items;
				}
			},

			async getDetails(val) {
				var res = await this.postdata("/api/Goods/GoodsInfo", {
					queryId: val,
				})
				if (res.code == 200) {
					this.productDetails = res.data
					this.Content = res.data.Content
					this.getData()
				}
			},
			// 加入购物车
			async addShoppingCart() {
				// 判断是否登录,没有登录则显示登录组件
				if (!this.$store.getters.getUser) {
					this.$store.dispatch("setShowLogin", true);
					return;
				}
				const res = await this.postdata('/api/Goods/CartOperation', {
					GoodsId: this.productDetails.Id,
					SkuId: this.productDetails.SkuViewList[0].Id,
					Num: this.productDetails.SkuViewList[0].Num
				})
				if (res.code == 200) {
					this.unshiftShoppingCart(res.data.Info);
					this.notifySucceed('加入购物车成功');
				} else {
					this.notifyError(res.msg);
				}
			},
			payImmediately() {
				// 判断是否登录,没有登录则显示登录组件
				if (!this.$store.getters.getUser) {
					this.$store.dispatch("setShowLogin", true);
					return;
				}
				this.$router.push({
					name: 'ConfirmOrder',
					query: {
						type: 2,
						GoodsId: this.productID,
						SkuId: this.productDetails.SkuViewList[0].Id,
						Num: this.productNum
					}
				})
			},
			addCollect() {
				// 判断是否登录,没有登录则显示登录组件
				if (!this.$store.getters.getUser) {
					this.$store.dispatch("setShowLogin", true);
					return;
				}
				this.$axios
					.post("/api/user/collect/addCollect", {
						user_id: this.$store.getters.getUser.user_id,
						product_id: this.productID
					})
					.then(res => {
						if (res.data.code == "001") {
							// 添加收藏成功
							this.notifySucceed(res.data.msg);
						} else {
							// 添加收藏失败
							this.notifyError(res.data.msg);
						}
					})
					.catch(err => {
						return Promise.reject(err);
					});
			},
			currentClick(index) {
				this.detailIndex = index
			},

		}
	};
</script>

<style lang="less" scoped>

</style>
<style scoped>
	.Bottomdetail {
		float: right;
		width: 960px;
	}

	.goods .breadcrumb {
		/* height: 50px; */
		/* background-color: white; */
		/* padding-top: 16px; */
		margin-top: -20px;
	}

	.goods .breadcrumb .el-breadcrumb {
		width: 1225px;
		line-height: 30px;
		font-size: 12px;
		margin: 0 auto;
	}

	.main .product-detail-top {
		background-color: #fff;
		padding: 0 20px;
	}

	.main .product-detail-top .msg {
		padding: 35px 0 40px;
	}

	.main .product-detail-top .msg .img-div {
		width: 220px;
	}

	.main .product-detail-top .msg .img-div img {
		height: 220px;
		width: 220px;
	}

	.main .product-detail-top .msg .img-div p {
		font-size: 14px;
		color: #999999;
		text-align: center;
		line-height: 25px;
		padding-top: 20px;
	}

	.main .product-detail-top .msg .parameter-div {
		width: 590px;
		margin-left: 12px;
	}

	.main .product-detail-top .msg .parameter-div .title {
		font-size: 16px;
		color: #333;
		padding: 0 18px;
		line-height: 28px;
	}

	.main .product-detail-top .msg .parameter-div .parameter-items {
		padding-top: 10px;
	}

	.main .product-detail-top .msg .parameter-div .parameter-items .parameter-item.parameter-item-l {
		width: 325px;
	}

	.main .product-detail-top .msg .parameter-div .parameter-items .parameter-item {
		margin-left: 18px;
	}

	.lb-box {
		font-size: 0 !important;
	}

	.main .product-detail-top .msg .parameter-div .parameter-items .parameter-item.parameter-item-r {
		width: 225px;
	}

	.main .product-detail-top .msg .parameter-div .parameter-items .parameter-item span.item-span {
		line-height: 16px;
		width: 55px;
	}

	.main .product-detail-top .msg .parameter-div .parameter-items .parameter-item .zzs-p {
		width: 250px;
		word-wrap: break-word;
		word-break: break-all;
		font-size: 12px;
		color: #333;
	}

	.main .product-detail-top .msg .parameter-div .parameter-items .parameter-item .zzs-p a {
		color: #333;
	}

	.main .product-detail-top .msg .parameter-div .parameter-items .parameter-item span.item-span {
		line-height: 16px;
		width: 55px;
	}

	.main .product-detail-top .msg .parameter-div .parameter-items .parameter-item span {
		font-size: 12px;
		color: #333;
	}

	.main .product-detail-top .msg .parameter-div .parameter-items .parameter-item p {
		word-wrap: break-word;
		word-break: break-all;
		font-size: 12px;
		color: #333;
		line-height: 16px;
	}

	.main .product-detail-top .msg .parameter-div .clf {
		padding-top: 10px;
		margin-left: 18px;
	}

	.main .product-detail-top .msg .parameter-div .clf span {
		line-height: 16px;
		width: 55px;
		font-size: 12px;
	}

	.main .product-detail-top .msg .parameter-div .clf p {
		width: 480px;
		line-height: 16px;
		font-size: 12px;
	}

	.main .product-detail-top .msg .parameter-div .desc {
		margin: 10px 0 0 18px;
	}

	.main .product-detail-top .msg .parameter-div .desc span {
		display: block;
		width: 55px;
		line-height: 22px;
	}

	.main .product-detail-top .msg .parameter-div .desc p {
		width: 480px;
		line-height: 22px;
	}

	.main .product-detail-top .msg .parameter-div .addr-pric {
		background: #fbfbfb;
		border: 1px solid #eaeaea;
		margin: 12px 39px 0 18px;
		padding-bottom: 10px;
	}

	.main .product-detail-top .msg .parameter-div .addr-pric .address-choose {
		margin-top: 10px;
		line-height: 20px;
	}

	.main .product-detail-top .msg .parameter-div .addr-pric .address-choose .text {
		font-size: 12px;
		color: #333;
		margin-left: 18px;
		width: 55px;
	}

	.main .product-detail-top .msg .parameter-div .addr-pric .address-choose .item-choose {
		cursor: pointer;
	}

	.main .product-detail-top .msg .parameter-div .addr-pric .address-choose .item-choose span {
		font-size: 12px;
		color: #333;
	}

	.main .product-detail-top .msg .parameter-div .addr-pric .price-operate {
		position: relative;
		margin-top: 10px;
		line-height: 23px;
	}

	.main .product-detail-top .msg .parameter-div .addr-pric .price-operate .text {
		font-size: 12px;
		color: #333;
		margin-left: 18px;
	}

	.main .product-detail-top .msg .parameter-div .addr-pric .price-operate .account-num {
		margin-right: 40px;
	}

	.main .product-detail-top .msg .parameter-div .addr-pric .price-operate .one-price.total-price,
	.main .product-detail-top .msg .parameter-div .addr-pric .price-operate .total-price.total-price {
		margin-left: 40px;
	}

	.main .product-detail-top .msg .parameter-div .addr-pric .price-operate .one-price span,
	.main .product-detail-top .msg .parameter-div .addr-pric .price-operate .total-price span {
		font-size: 12px;
	}

	.main .product-detail-top .msg .parameter-div .addr-pric .price-operate .one-price span.price-text,
	.main .product-detail-top .msg .parameter-div .addr-pric .price-operate .total-price span.price-text {
		color: #eb222b;
	}

	.main .product-detail-top .msg .parameter-div .btns {
		margin: 25px 39px 0 0;
		padding-left: 18px;
	}

	.main .product-detail-top .msg .parameter-div .btns .btn {
		display: block;
		width: 120px;
		height: 36px;
		line-height: 34px;
		text-align: center;
	}

	.main .product-detail-top .msg .parameter-div .btns .btn.btn-r-b {
		margin-left: 30px;
	}

	.main .common-btn-red-border:hover {
		background-color: #c9242c;
		border: 1px solid #c9242c;
	}

	.main .common-btn-red-white-border:hover {
		background-color: #ffeeee;
	}

	.main .product-detail-bottom {
		margin-top: 12px;
		color: #333;
		overflow: hidden;
	}

	.main .product-detail-bottom .left-section {
		background-color: #fff;
		/* width: 945px; */
		width: 100%;
		padding: 25px 29px 30px;
		padding-bottom: 9999px;
		margin-bottom: -9999px;
	}

	.main .product-detail-bottom .left-section .left-nav-ul {
		border-bottom: 1px solid #09c867;
	}

	.main .product-detail-bottom .left-section .left-nav-ul .left-nav-li {
		width: 120px;
		height: 38px;
		background: #fbfbfb;
		border-left: 1px solid #efefef;
		border-top: 1px solid #efefef;
		color: #333333;
		font-size: 14px;
		cursor: pointer;
		text-align: center;
		line-height: 36px;
	}

	.main .product-detail-bottom .left-section .left-nav-ul .left-nav-li.active {
		border-color: #09c867;
		background-color: #09c867;
		color: #fff;
		font-weight: 600;
	}

	.main .product-detail-bottom .left-section .left-nav-ul .left-nav-li.last-li {
		border-right: 1px solid #efefef;
	}

	.main .product-detail-bottom .right-section {
		width: 268px;
		margin-left: 12px;
		font-size: 14px;
		color: #333;
		padding-bottom: 9999px;
		margin-bottom: -9999px;
	}

	.main .product-detail-bottom .right-section .classify-section {
		background-color: #fff;
		height: 523px;
		margin-bottom: 12px;
	}

	.main .product-detail-bottom .right-section .classify-section .classify-items {
		height: 440px;
		overflow: auto;
	}

	.main .product-detail-bottom .right-section .classify-section .classify-items .classify-item {
		height: 32px;
		padding: 12px 10px 0 56px;
		display: block;
		color: #333;
	}

	.main .product-detail-bottom .right-section .classify-section .classify-items .classify-item .text {
		display: block;
		width: 100px;
		color: #333;
	}

	.main .product-detail-bottom .right-section .classify-section .classify-items .classify-item .num {
		display: block;
		font-weight: normal;
	}
</style>